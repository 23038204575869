.bg-dark-overlay:after {
  background-color: rgba(0, 0, 0, 0.3) !important;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* ::selection {
  background-color: red;
  color: white !important;
} */
